export default class ErpOrderEntity {
    static getEntityData(elem: any) {
        return {
            id: elem.querySelector("#erp_order_id") ? (elem.querySelector("#erp_order_id") as HTMLInputElement).value : null,
            state: {
                technicalName: (elem.querySelector("#erp_order_status_id option:checked") as HTMLInputElement).value,
                actionName: (elem.querySelector("#erp_order_status_id option:checked") as HTMLInputElement).getAttribute("data-transition")
            },
            deliveryState: {
                technicalName: (elem.querySelector("#erp_order_delivery_status_id option:checked") as HTMLInputElement).value,
                actionName: (elem.querySelector("#erp_order_delivery_status_id option:checked") as HTMLInputElement).getAttribute("data-transition")
            },
            billingAddress: {
                company: elem.querySelector("#erp_order_invoiceAddress_company").value,
                firstName: elem.querySelector("#erp_order_invoiceAddress_firstName").value,
                lastName: elem.querySelector("#erp_order_invoiceAddress_lastName").value,
                street: elem.querySelector("#erp_order_invoiceAddress_street").value,
                zipcode: elem.querySelector("#erp_order_invoiceAddress_zipcode").value,
                city: elem.querySelector("#erp_order_invoiceAddress_city").value,
            },
            shippingOrderAddress: {
                company: elem.querySelector("#erp_order_deliveryAddress_company").value,
                firstName: elem.querySelector("#erp_order_deliveryAddress_firstName").value,
                lastName: elem.querySelector("#erp_order_deliveryAddress_lastName").value,
                street: elem.querySelector("#erp_order_deliveryAddress_street").value,
                zipcode: elem.querySelector("#erp_order_deliveryAddress_zipcode").value,
                city: elem.querySelector("#erp_order_deliveryAddress_city").value,
                customFields: {
                    fltools_customer_location_uuid: elem.querySelector("#erp_order_deliveryAddress_fltools_uuid").value,
                }
            }
        }
    }
}